@import "~node_modules/quill/dist/quill.snow.css";
@layer element, layout, helper, component;
html {
  min-width: 22.5rem;
}

@layer element {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  :focus-visible {
    outline: none;
  }
  :root {
    --gutter: 1rem;
    --padding: 0.75rem;
    --radius: 0.375rem;
  }
  @media (prefers-reduced-motion: no-preference) {
    :root {
      scroll-behavior: smooth;
    }
  }
  body {
    background-color: var(--bg);
    color: var(--color);
    margin: 0;
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    text-align: left;
  }
  hr {
    background-color: currentColor;
    margin-bottom: var(--gutter);
    margin-top: var(--gutter);
    opacity: 0.25;
    border: none;
    border-bottom: 1px solid;
  }
  ol, ul {
    padding-left: 1rem;
  }
  ol, ul, dl {
    margin-top: 0;
    margin-bottom: 0;
  }
  dt {
    font-weight: 700;
  }
  dd {
    margin-bottom: calc(var(--gutter) / 2);
    margin-left: calc(var(--gutter) / 2);
  }
  dd:last-child {
    margin-bottom: 0;
  }
  pre, code {
    color: var(--tone-danger-color);
    font-family: monospace;
  }
  pre {
    display: block;
    margin-top: 0;
    margin-bottom: var(--gutter);
    overflow: auto;
  }
  pre code {
    color: inherit;
    word-break: normal;
  }
  code {
    word-wrap: break-word;
  }
  figure {
    margin: 0;
  }
  [hidden] {
    display: none;
  }
  mark {
    background-color: var(--tone-primary-bg);
    color: var(--tone-primary-color);
  }
  figure img {
    display: block;
  }
  img {
    display: inline-block;
    max-width: 100%;
    height: auto;
  }
}
@layer helper {
  .text-spacing,
  .gutter-vertical-gap {
    flex-direction: column;
    gap: var(--gutter);
    display: flex;
  }
  .text-spacing > *,
  .gutter-vertical-gap > * {
    margin: 0 !important;
  }
  .gutter-top {
    margin-top: var(--gutter);
  }
  .gutter-bottom {
    margin-bottom: var(--gutter);
  }
  .mx-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .w-100 {
    width: 100%;
  }
  .text-center {
    text-align: center;
  }
  .text-start {
    text-align: left;
  }
  .text-end {
    text-align: right;
  }
  .text-small {
    font-size: 90%;
  }
  .alignment-left {
    text-align: left;
  }
  .alignment-center {
    text-align: center;
  }
  .alignment-right {
    text-align: right;
  }
  .faded {
    opacity: 0.6;
  }
  .text-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .overflow-x {
    overflow-x: auto;
  }
  .spinning {
    animation-name: spin;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  .inline-group > *:not(:last-child) {
    margin-right: var(--padding);
  }
}
@layer layout {
  .breadcrumb {
    display: flex;
    gap: 0.5rem;
  }
  .breadcrumb > *:not(:first-child) {
    display: block;
  }
  .breadcrumb > *:not(:first-child):before {
    color: var(--tone-secondary-color);
    display: inline-block;
    padding-right: 0.1rem;
    content: "/";
    opacity: 0.5;
  }
}
@layer element {
  textarea:not(.styled),
  button:not(.styled),
  select:not(.styled),
  input:not(.styled),
  .input,
  .button,
  optgroup {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  .placeholder,
  ::placeholder {
    color: var(--placeholder);
    opacity: 1;
  }
  .help-text {
    color: var(--tone-secondary-color);
    font-style: italic;
    font-size: 0.9em;
  }
  .button,
  button,
  select {
    text-transform: none;
  }
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  legend {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    line-height: inherit;
  }
  .label,
  label {
    margin-bottom: calc(var(--gutter) / 2);
    display: inline-block;
  }
  input[type=file] {
    height: calc(1.5rem + var(--padding) + 2px);
    vertical-align: middle;
    line-height: 1.5;
    font-size: 1rem;
  }
  .input-wrapper {
    border-radius: var(--radius);
    width: 100%;
  }
  .active input:not([type=radio]):not([type=checkbox]),
  .active textarea,
  .active select,
  .active .button,
  .active button {
    --border: hsl(
                    var(--link-border-hue),
                    var(--link-border-saturation),
                    calc(var(--link-border-lightness) + (5 * clamp(-1%, 50% - var(--link-border-lightness), 1%)))
    );
  }
  input:not([type=radio]):not([type=checkbox]):not(.styled),
  textarea:not(.styled),
  select:not(.styled),
  button:not(.styled),
  .input,
  .button {
    background-color: var(--input-bg);
    border: 1px solid var(--border);
    color: var(--color);
    padding: calc(var(--padding) / 2) var(--padding);
    height: calc(1.5rem + var(--padding) + 2px);
    vertical-align: middle;
    line-height: 1.5;
    font-size: 1rem;
    border-radius: var(--radius);
  }
  input:not([type=radio]):not([type=checkbox]):not(.styled).active,
  textarea:not(.styled).active,
  select:not(.styled).active,
  button:not(.styled).active,
  .input.active,
  .button.active {
    --border: hsl(
                    var(--border-hue),
                    var(--border-saturation),
                    calc(var(--border-lightness) + (5 * clamp(-1%, 50% - var(--border-lightness), 1%)))
    );
  }
  input:not([type=radio]):not([type=checkbox]):not(.styled):focus,
  textarea:not(.styled):focus,
  select:not(.styled):focus,
  button:not(.styled):focus,
  .input:focus,
  .button:focus {
    --border: hsl(
                    var(--border-hue),
                    var(--border-saturation),
                    calc(var(--border-lightness) + (10 * clamp(-1%, 50% - var(--border-lightness), 1%)))
    );
  }
  input:not([type=radio]):not([type=checkbox]):not(.styled)[readonly],
  textarea:not(.styled)[readonly],
  select:not(.styled)[readonly],
  button:not(.styled)[readonly],
  .input[readonly],
  .button[readonly] {
    opacity: 0.7;
  }
  input:not(:enabled):not([href]), input.disabled, textarea:not(:enabled):not([href]), textarea.disabled, select:not(:enabled):not([href]), select.disabled, button:not(:enabled):not([href]), button.disabled, a:not(:enabled):not([href]), a.disabled {
    pointer-events: all;
    cursor: not-allowed;
    opacity: 0.5;
  }
  .button,
  button:not(.styled) {
    background-color: var(--bg);
    transition: 0.15s background-color;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    border-width: 0;
    padding-bottom: calc(var(--padding) / 2 - 1px);
    padding-top: calc(var(--padding) / 2 + 1px);
    border-bottom-width: 2px;
  }
  .button:hover:not(.disabled):enabled, .button:hover[href]:not(.disabled), .button.active:not(.disabled):enabled, .button.active[href]:not(.disabled),
  button:not(.styled):hover:not(.disabled):enabled,
  button:not(.styled):hover[href]:not(.disabled),
  button:not(.styled).active:not(.disabled):enabled,
  button:not(.styled).active[href]:not(.disabled) {
    background-color: hsl(var(--bg-hue), var(--bg-saturation), calc(var(--bg-lightness) + 5 * clamp(-1%, 50% - var(--bg-lightness), 1%)));
    padding-bottom: calc(var(--padding) / 2 + 1px);
    padding-top: calc(var(--padding) / 2 - 1px);
    border-bottom-width: 0;
    border-top-width: 2px;
  }
  .button[type=submit],
  button:not(.styled)[type=submit] {
    --tone-bg-hue: var(--tone-primary-bg-hue);
    --tone-bg-saturation: var(--tone-primary-bg-saturation);
    --tone-bg-lightness: var(--tone-primary-bg-lightness);
    --tone-border-hue: var(--tone-primary-border-hue);
    --tone-border-saturation: var(--tone-primary-border-saturation);
    --tone-border-lightness: var(--tone-primary-border-lightness);
    --tone-color-hue: var(--tone-primary-color-hue);
    --tone-color-saturation: var(--tone-primary-color-saturation);
    --tone-color-lightness: var(--tone-primary-color-lightness);
    background-color: var(--tone-primary-bg);
    border-color: var(--tone-primary-border);
    color: var(--tone-primary-color);
  }
  .button[type=submit]:hover:not(.disabled):enabled, .button[type=submit].active:not(.disabled):enabled,
  button:not(.styled)[type=submit]:hover:not(.disabled):enabled,
  button:not(.styled)[type=submit].active:not(.disabled):enabled {
    --opacity: .75;
    background-color: hsl(var(--tone-primary-bg-hue), var(--tone-primary-bg-saturation), calc(var(--tone-primary-bg-lightness) + 5 * clamp(-1%, 50% - var(--tone-primary-bg-lightness), 1%)));
  }
  select {
    word-wrap: normal;
    appearance: none;
  }
  select:not([multiple]) {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23141a20' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
  }
  select[multiple] {
    resize: vertical;
    min-height: 5rem;
  }
  select:not(:enabled) {
    opacity: 1;
  }
  textarea:not(.styled) {
    resize: vertical;
    height: auto;
  }
  .form-success button[type=submit] {
    background-color: var(--tone-success-bg) !important;
    border-color: var(--tone-success-border) !important;
    color: var(--tone-success-color) !important;
  }
  input {
    accent-color: var(--tone-primary-color);
  }
  input:autofill {
    background: inherit;
  }
  textarea,
  select,
  input:not([type=radio]):not([type=checkbox]) {
    display: block;
    width: 100%;
  }
}
@layer layout {
  .input-row > .form-part,
  .input-line {
    display: flex;
    gap: 0;
  }
  .input-row > .form-part label,
  .input-line label {
    margin-bottom: 0;
  }
  .input-row > .form-part label:has(less-shared-icon),
  .input-line label:has(less-shared-icon) {
    width: calc(1.25rem + var(--padding) * 2);
    justify-content: center;
  }
  .input-row > .form-part > input, .input-row > .form-part > .input, .input-row > .form-part > select, .input-row > .form-part > .input-wrapper, .input-row > .form-part > .form-control-wrapper,
  .input-line > input,
  .input-line > .input,
  .input-line > select,
  .input-line > .input-wrapper,
  .input-line > .form-control-wrapper {
    flex-grow: 1;
  }
  .input-row > .form-part > :not(input):not(select):not(.input-wrapper):not(.form-control-wrapper):not(.input),
  .input-line > :not(input):not(select):not(.input-wrapper):not(.form-control-wrapper):not(.input) {
    flex-grow: 0;
    flex-shrink: 0;
  }
  .input-row > .form-part > .input-line-text, .input-row > .form-part > label, .input-row > .form-part > button,
  .input-line > .input-line-text,
  .input-line > label,
  .input-line > button {
    text-overflow: ellipsis;
    max-height: 2.5rem;
    overflow: hidden;
  }
  .input-row > .form-part > *:not(:first-child), .input-row > .form-part > *:not(:first-child) *,
  .input-line > *:not(:first-child),
  .input-line > *:not(:first-child) * {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .input-row > .form-part > *:not(:last-child), .input-row > .form-part > *:not(:last-child) *,
  .input-line > *:not(:last-child),
  .input-line > *:not(:last-child) * {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .input-row > .form-part > *:not(:first-child), .input-row > .form-part > *:not(:first-child) *,
  .input-line > *:not(:first-child),
  .input-line > *:not(:first-child) * {
    border-left: none;
  }
  .input-row > .form-part > *:first-child,
  .input-line > *:first-child {
    border-top-left-radius: var(--radius);
    border-bottom-left-radius: var(--radius);
  }
  .input-row > .form-part > *:last-child,
  .input-line > *:last-child {
    border-top-right-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
  }
  .input-row > .form-part label,
  .input-row > .form-part .input-line-text,
  .input-line label,
  .input-line .input-line-text {
    padding: calc(var(--padding) / 2 - 1px) var(--padding);
    background-color: var(--tone-bg);
    border: solid 1px var(--border);
    white-space: nowrap;
    align-items: center;
    display: flex;
  }
  .input-row {
    gap: var(--gutter);
    display: flex;
  }
  .input-row > *:not(button) {
    width: 100%;
  }
  legend .form-part {
    display: inline-block;
    font-size: 0.8rem;
  }
  .form-actions {
    align-items: center;
    gap: var(--gutter);
    display: flex;
  }
  .form,
  form {
    container-type: inline-size;
  }
  .form:has(:focus), .form:has(.show-dropdown),
  form:has(:focus),
  form:has(.show-dropdown) {
    position: relative;
    z-index: 5;
  }
  @container (min-width: 32.5rem) {
    .form > .form-part,
    .form :not(legend):not(.input-row) > .form-part,
    form:not(.form-alert) > .form-part,
    form:not(.form-alert) :not(legend):not(.input-row) > .form-part {
      grid-template-columns: 8rem 1fr;
      gap: calc(var(--gutter) / 3) var(--gutter);
      display: grid;
    }
    .form > .form-part > *,
    .form :not(legend):not(.input-row) > .form-part > *,
    form:not(.form-alert) > .form-part > *,
    form:not(.form-alert) :not(legend):not(.input-row) > .form-part > * {
      margin-bottom: 0;
    }
    .form > .form-part > label, .form > .form-part > .label,
    .form :not(legend):not(.input-row) > .form-part > label,
    .form :not(legend):not(.input-row) > .form-part > .label,
    form:not(.form-alert) > .form-part > label,
    form:not(.form-alert) > .form-part > .label,
    form:not(.form-alert) :not(legend):not(.input-row) > .form-part > label,
    form:not(.form-alert) :not(legend):not(.input-row) > .form-part > .label {
      padding-top: calc(var(--padding) / 2);
      overflow-wrap: break-word;
      overflow-x: hidden;
      text-align: right;
      grid-column: 1;
      hyphens: auto;
    }
    .form > .form-part > *:not(label):not(.label),
    .form :not(legend):not(.input-row) > .form-part > *:not(label):not(.label),
    form:not(.form-alert) > .form-part > *:not(label):not(.label),
    form:not(.form-alert) :not(legend):not(.input-row) > .form-part > *:not(label):not(.label) {
      grid-column: 2;
    }
    .form > .legend, .form > legend, .form > p, .form > .form-actions, .form fieldset > .legend, .form fieldset > legend, .form fieldset > p, .form fieldset > .form-actions, .form .fieldset > .legend, .form .fieldset > legend, .form .fieldset > p, .form .fieldset > .form-actions,
    form:not(.form-alert) > .legend,
    form:not(.form-alert) > legend,
    form:not(.form-alert) > p,
    form:not(.form-alert) > .form-actions,
    form:not(.form-alert) fieldset > .legend,
    form:not(.form-alert) fieldset > legend,
    form:not(.form-alert) fieldset > p,
    form:not(.form-alert) fieldset > .form-actions,
    form:not(.form-alert) .fieldset > .legend,
    form:not(.form-alert) .fieldset > legend,
    form:not(.form-alert) .fieldset > p,
    form:not(.form-alert) .fieldset > .form-actions {
      padding-left: calc(8rem + var(--gutter));
    }
  }
  .control-sm {
    padding-bottom: 0.1rem;
    padding-top: 0.1rem;
    font-size: 0.8rem;
    line-height: 1rem;
    height: 1.2rem;
  }
  button.control-sm,
  .button.control-sm {
    padding-bottom: calc(0.1rem - 1px);
    padding-top: 0.1rem;
    border-bottom-width: 1px;
  }
  button.control-sm:hover:not(.disabled):enabled, button.control-sm.active:not(.disabled):enabled,
  .button.control-sm:hover:not(.disabled):enabled,
  .button.control-sm.active:not(.disabled):enabled {
    padding-bottom: 0.1rem;
    padding-top: calc(0.1rem - 1px);
    border-bottom-width: 0;
    border-top-width: 1px;
  }
}
@layer layout {
  .hover-container .hover-faded {
    transition: opacity 0.5s;
  }
  .hover-container:not(:hover) .hover-hidden {
    display: none !important;
  }
  .hover-container:not(:hover) .hover-faded {
    opacity: 0.5;
  }
}
@layer layout {
  .layout-sidebar {
    grid-gap: var(--gutter);
    display: grid;
    width: 100%;
  }
  .layout-sidebar > * {
    min-width: 0;
  }
  @media screen and (min-width: 1120px) {
    .layout-sidebar {
      grid-template-columns: 1fr 20rem;
    }
    .layout-sidebar .main {
      grid-column: 1;
    }
    .layout-sidebar .sidebar {
      grid-column: 2;
    }
    @supports not (grid-template-rows: masonry) {
      .layout-sidebar {
        grid-auto-flow: dense;
      }
    }
    /* Only supported in FF via about:config */
    /* https://caniuse.com/mdn-css_properties_grid-template-rows_masonry */
    @supports (grid-template-rows: masonry) {
      .layout-sidebar {
        grid-template-rows: masonry;
      }
    }
  }
  .layout-inline-bar > *:not(:last-child) {
    margin-right: 0.5rem;
  }
  .layout-focussed,
  .layout-focussed-lg {
    padding-right: 1rem;
    padding-left: 1rem;
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  .layout-focussed {
    max-width: 42rem;
  }
  .layout-focussed-lg {
    max-width: 60rem;
  }
  .layout-sided .layout-focussed {
    max-width: 40rem;
    padding-right: 0;
    padding-left: 0;
  }
  .layout-bar {
    justify-content: space-between;
    display: flex !important;
    align-items: center;
  }
  .layout-bar > *:not(:last-child) {
    margin-right: var(--gutter);
  }
}
@layer element {
  a {
    --color: var(--link-color, #3183fd);
    color: var(--link-color, #3183fd);
    vertical-align: baseline;
    text-decoration: none;
    background: none;
    display: inline;
    line-height: 1;
    height: auto;
    border: none;
    padding: 0;
  }
  a:hover {
    --color: hsl(
                    var(--link-color-hue),
                    var(--link-color-saturation),
                    calc(var(--link-color-lightness) + (10 * clamp(-1%, 50% - var(--link-color-lightness), 1%)))
    );
    color: hsl(var(--link-color-hue), var(--link-color-saturation), calc(var(--link-color-lightness) + 10 * clamp(-1%, 50% - var(--link-color-lightness), 1%)));
  }
}
@layer layout {
  .link {
    --color: var(--link-color, #3183fd);
    color: var(--link-color, #3183fd);
    text-decoration: none;
    text-align: left;
    background: none;
    display: inline;
    line-height: 1;
    height: auto;
    border: none;
    padding: 0;
  }
  .link:hover {
    --color: hsl(
                    var(--link-color-hue),
                    var(--link-color-saturation),
                    calc(var(--link-color-lightness) + (10 * clamp(-1%, 50% - var(--link-color-lightness), 1%)))
    );
    color: hsl(var(--link-color-hue), var(--link-color-saturation), calc(var(--link-color-lightness) + 10 * clamp(-1%, 50% - var(--link-color-lightness), 1%)));
  }
  .link.active {
    --color: hsl(
                    var(--tone-primary-color-hue),
                    var(--tone-primary-color-saturation),
                    calc(var(--tone-primary-color-lightness) + (10 * clamp(-1%, 50% - var(--tone-primary-color-lightness), 1%)))
    );
    color: hsl(var(--tone-primary-color-hue), var(--tone-primary-color-saturation), calc(var(--tone-primary-color-lightness) + 10 * clamp(-1%, 50% - var(--tone-primary-color-lightness), 1%)));
  }
}
@layer element {
  .markdown > *:last-child {
    margin-bottom: 0;
  }
}
.progress-wrapper {
  position: relative;
}
.progress-wrapper:after {
  border-top: solid 1px var(--tone-success-border);
  transition: width 1s ease-in-out;
  width: var(--progress);
  position: absolute;
  content: " ";
  bottom: 0;
  left: 0;
}

.segment-editor {
  display: flex;
}
.segment-editor.segment-editor-contents {
  flex-direction: row;
}
.segment-editor.segment-editor-contents > .segment-editor-controls {
  flex-direction: column;
}
.segment-editor.segment-editor-contents:not(.segment-editor-image) > .segment-editor-viewer {
  padding: calc(var(--padding) / 2) var(--padding);
}
.segment-editor.segment-editor-style {
  flex-direction: column;
}
.segment-editor.segment-editor-style > .segment-editor-controls {
  flex-direction: row;
}
.segment-editor.segment-editor-image .segment-editor-viewer {
  align-items: center;
}
.segment-editor .segment-editor-controls {
  --opacity: 50%;
  background-color: hsl(var(--tone-info-bg-hsl)/50%);
  padding: calc(var(--padding) / 2);
  gap: var(--gutter);
  display: flex;
}
.segment-editor .segment-editor-controls .link {
  opacity: 0.2;
}
.segment-editor .segment-editor-controls:hover {
  --opacity: 70%;
  background-color: hsl(var(--tone-info-bg-hsl)/70%);
}
.segment-editor .segment-editor-controls:hover .link {
  opacity: 1;
}
.segment-editor .segment-editor-viewer {
  justify-content: center;
  flex-direction: column;
  display: flex;
  flex-grow: 1;
}
.segment-editor:hover > .segment-editor-controls {
  background-color: hsl(var(--tone-info-bg-hsl)/70%);
}

@layer layout {
  svg {
    display: inline-block;
    fill: currentColor;
    margin: 0 auto;
    height: 100%;
    width: auto;
  }
}
@layer element {
  table {
    border-collapse: collapse;
    caption-side: bottom;
    table-layout: fixed;
    width: 100%;
  }
  caption {
    padding-top: calc(var(--gutter) / 2);
    padding-bottom: calc(var(--gutter) / 2);
    color: #6c757d;
    text-align: left;
  }
  th {
    text-align: inherit;
    text-align: -webkit-match-parent;
  }
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }
  thead:not(:first-child), tfoot:not(:first-child) {
    border-top: solid 1px var(--border);
  }
  thead:not(:last-child), tfoot:not(:last-child) {
    border-bottom: solid 1px var(--border);
  }
  td, th {
    padding: calc(var(--padding) / 3) calc(var(--padding) / 2);
    background-color: var(--tone-bg);
    text-overflow: ellipsis;
    overflow: hidden;
  }
  td.multi-row, th.multi-row {
    overflow: auto;
  }
  td:first-child, th:first-child {
    padding-left: var(--padding);
  }
  td:last-child, th:last-child {
    padding-right: var(--padding);
  }
  tbody td, tbody th {
    border-top: solid 1px var(--border);
  }
}
@layer layout {
  .table-iconed td:first-child, .table-iconed th:first-child {
    width: 2.2rem;
  }
}
@layer element {
  h6, h5, h4, h3, h2, h1 {
    margin-bottom: 0;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 0;
  }
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1rem;
  }
  p {
    margin-bottom: 0;
    margin-top: 0;
  }
  time[title],
  abbr[title] {
    text-decoration: underline dotted;
    cursor: help;
  }
  b, strong {
    font-weight: bolder;
  }
  small {
    font-size: 0.875em;
  }
}
:root {
  --input-bg-hue: 208;
  --input-bg-saturation: 8%;
  --input-bg-lightness: 62%;
  --placeholder-hue: 210;
  --placeholder-saturation: 4%;
  --placeholder-lightness: 51%;
  --bg-hue: 209;
  --bg-saturation: 14%;
  --bg-lightness: 68%;
  --color-hue: 26;
  --color-saturation: 6%;
  --color-lightness: 20%;
  --border-hue: 209;
  --border-saturation: 14%;
  --border-lightness: 43%;
  --link-color-hue: 216;
  --link-color-saturation: 98%;
  --link-color-lightness: 30%;
  --link-color: hsl(var(--link-color-hue), var(--link-color-saturation), var(--link-color-lightness));
  --tone-primary-bg-hue: 180;
  --tone-primary-bg-saturation: 19%;
  --tone-primary-bg-lightness: 70%;
  --tone-primary-color-hue: 175;
  --tone-primary-color-saturation: 82%;
  --tone-primary-color-lightness: 15%;
  --tone-primary-border-hue: 60;
  --tone-primary-border-saturation: 19%;
  --tone-primary-border-lightness: 35%;
  --tone-secondary-bg-hue: 180;
  --tone-secondary-bg-saturation: 9%;
  --tone-secondary-bg-lightness: 70%;
  --tone-secondary-color-hue: 180;
  --tone-secondary-color-saturation: 9%;
  --tone-secondary-color-lightness: 15%;
  --tone-secondary-border-hue: 180;
  --tone-secondary-border-saturation: 9%;
  --tone-secondary-border-lightness: 35%;
  --tone-success-color-hue: 76;
  --tone-success-color-saturation: 65%;
  --tone-success-color-lightness: 20%;
  --tone-success-bg-hue: 82;
  --tone-success-bg-saturation: 32%;
  --tone-success-bg-lightness: 85%;
  --tone-success-border-hue: 82;
  --tone-success-border-saturation: 32%;
  --tone-success-border-lightness: 44%;
  --tone-info-color-hue: 210;
  --tone-info-color-saturation: 72%;
  --tone-info-color-lightness: 30%;
  --tone-info-bg-hue: 210;
  --tone-info-bg-saturation: 33%;
  --tone-info-bg-lightness: 80%;
  --tone-info-border-hue: 210;
  --tone-info-border-saturation: 33%;
  --tone-info-border-lightness: 50%;
  --tone-warning-color-hue: 29;
  --tone-warning-color-saturation: 93%;
  --tone-warning-color-lightness: 20%;
  --tone-warning-bg-hue: 30;
  --tone-warning-bg-saturation: 41%;
  --tone-warning-bg-lightness: 80%;
  --tone-warning-border-hue: 30;
  --tone-warning-border-saturation: 41%;
  --tone-warning-border-lightness: 45%;
  --tone-danger-color-hue: 347;
  --tone-danger-color-saturation: 98%;
  --tone-danger-color-lightness: 25%;
  --tone-danger-bg-hue: 341;
  --tone-danger-bg-saturation: 31%;
  --tone-danger-bg-lightness: 85%;
  --tone-danger-border-hue: 341;
  --tone-danger-border-saturation: 31%;
  --tone-danger-border-lightness: 51%;
}

.theme-light {
  --input-bg-hue: 208;
  --input-bg-saturation: 8%;
  --input-bg-lightness: 62%;
  --placeholder-hue: 210;
  --placeholder-saturation: 4%;
  --placeholder-lightness: 51%;
  --bg-hue: 209;
  --bg-saturation: 14%;
  --bg-lightness: 68%;
  --color-hue: 26;
  --color-saturation: 6%;
  --color-lightness: 20%;
  --border-hue: 209;
  --border-saturation: 14%;
  --border-lightness: 43%;
  --link-color-hue: 216;
  --link-color-saturation: 98%;
  --link-color-lightness: 30%;
  --link-color: hsl(var(--link-color-hue), var(--link-color-saturation), var(--link-color-lightness));
  --tone-primary-bg-hue: 180;
  --tone-primary-bg-saturation: 19%;
  --tone-primary-bg-lightness: 70%;
  --tone-primary-color-hue: 175;
  --tone-primary-color-saturation: 82%;
  --tone-primary-color-lightness: 15%;
  --tone-primary-border-hue: 60;
  --tone-primary-border-saturation: 19%;
  --tone-primary-border-lightness: 35%;
  --tone-secondary-bg-hue: 180;
  --tone-secondary-bg-saturation: 9%;
  --tone-secondary-bg-lightness: 70%;
  --tone-secondary-color-hue: 180;
  --tone-secondary-color-saturation: 9%;
  --tone-secondary-color-lightness: 15%;
  --tone-secondary-border-hue: 180;
  --tone-secondary-border-saturation: 9%;
  --tone-secondary-border-lightness: 35%;
  --tone-success-color-hue: 76;
  --tone-success-color-saturation: 65%;
  --tone-success-color-lightness: 20%;
  --tone-success-bg-hue: 82;
  --tone-success-bg-saturation: 32%;
  --tone-success-bg-lightness: 85%;
  --tone-success-border-hue: 82;
  --tone-success-border-saturation: 32%;
  --tone-success-border-lightness: 44%;
  --tone-info-color-hue: 210;
  --tone-info-color-saturation: 72%;
  --tone-info-color-lightness: 30%;
  --tone-info-bg-hue: 210;
  --tone-info-bg-saturation: 33%;
  --tone-info-bg-lightness: 80%;
  --tone-info-border-hue: 210;
  --tone-info-border-saturation: 33%;
  --tone-info-border-lightness: 50%;
  --tone-warning-color-hue: 29;
  --tone-warning-color-saturation: 93%;
  --tone-warning-color-lightness: 20%;
  --tone-warning-bg-hue: 30;
  --tone-warning-bg-saturation: 41%;
  --tone-warning-bg-lightness: 80%;
  --tone-warning-border-hue: 30;
  --tone-warning-border-saturation: 41%;
  --tone-warning-border-lightness: 45%;
  --tone-danger-color-hue: 347;
  --tone-danger-color-saturation: 98%;
  --tone-danger-color-lightness: 25%;
  --tone-danger-bg-hue: 341;
  --tone-danger-bg-saturation: 31%;
  --tone-danger-bg-lightness: 85%;
  --tone-danger-border-hue: 341;
  --tone-danger-border-saturation: 31%;
  --tone-danger-border-lightness: 51%;
}

.theme-dark {
  --input-bg-hue: 30;
  --input-bg-saturation: 3%;
  --input-bg-lightness: 31%;
  --placeholder-hue: 209;
  --placeholder-saturation: 14%;
  --placeholder-lightness: 53%;
  --color-hue: 209;
  --color-saturation: 14%;
  --color-lightness: 72.5%;
  --bg-hue: 26;
  --bg-saturation: 6%;
  --bg-lightness: 20%;
  --border-hue: 26;
  --border-saturation: 6%;
  --border-lightness: 45%;
  --link-color-hue: 216;
  --link-color-saturation: 98%;
  --link-color-lightness: 80%;
  --link-color: hsl(var(--link-color-hue), var(--link-color-saturation), var(--link-color-lightness));
  --tone-primary-bg-hue: 172;
  --tone-primary-bg-saturation: 28%;
  --tone-primary-bg-lightness: 22%;
  --tone-primary-color-hue: 175;
  --tone-primary-color-saturation: 84%;
  --tone-primary-color-lightness: 48%;
  --tone-primary-border-hue: 172;
  --tone-primary-border-saturation: 28%;
  --tone-primary-border-lightness: 47%;
  --tone-secondary-bg-hue: 172;
  --tone-secondary-bg-saturation: 13%;
  --tone-secondary-bg-lightness: 22%;
  --tone-secondary-color-hue: 172;
  --tone-secondary-color-saturation: 13%;
  --tone-secondary-color-lightness: 72%;
  --tone-secondary-border-hue: 172;
  --tone-secondary-border-saturation: 13%;
  --tone-secondary-border-lightness: 47%;
  --tone-success-color-hue: 76;
  --tone-success-color-saturation: 64%;
  --tone-success-color-lightness: 70%;
  --tone-success-bg-hue: 73;
  --tone-success-bg-saturation: 31%;
  --tone-success-bg-lightness: 25%;
  --tone-success-border-hue: 73;
  --tone-success-border-saturation: 31%;
  --tone-success-border-lightness: 60%;
  --tone-info-color-hue: 211;
  --tone-info-color-saturation: 72%;
  --tone-info-color-lightness: 90%;
  --tone-info-bg-hue: 210;
  --tone-info-bg-saturation: 15%;
  --tone-info-bg-lightness: 35%;
  --tone-info-border-hue: 210;
  --tone-info-border-saturation: 15%;
  --tone-info-border-lightness: 67%;
  --tone-warning-color-hue: 29;
  --tone-warning-color-saturation: 93%;
  --tone-warning-color-lightness: 75%;
  --tone-warning-bg-hue: 28;
  --tone-warning-bg-saturation: 40%;
  --tone-warning-bg-lightness: 25%;
  --tone-warning-border-hue: 28;
  --tone-warning-border-saturation: 40%;
  --tone-warning-border-lightness: 61%;
  --tone-danger-color-hue: 347;
  --tone-danger-color-saturation: 100%;
  --tone-danger-color-lightness: 90%;
  --tone-danger-bg-hue: 349;
  --tone-danger-bg-saturation: 20%;
  --tone-danger-bg-lightness: 35%;
  --tone-danger-border-hue: 349;
  --tone-danger-border-saturation: 20%;
  --tone-danger-border-lightness: 68%;
}

@media (prefers-color-scheme: dark) {
  :root {
    --input-bg-hue: 30;
    --input-bg-saturation: 3%;
    --input-bg-lightness: 31%;
    --placeholder-hue: 209;
    --placeholder-saturation: 14%;
    --placeholder-lightness: 53%;
    --color-hue: 209;
    --color-saturation: 14%;
    --color-lightness: 72.5%;
    --bg-hue: 26;
    --bg-saturation: 6%;
    --bg-lightness: 20%;
    --border-hue: 26;
    --border-saturation: 6%;
    --border-lightness: 45%;
    --link-color-hue: 216;
    --link-color-saturation: 98%;
    --link-color-lightness: 80%;
    --link-color: hsl(var(--link-color-hue), var(--link-color-saturation), var(--link-color-lightness));
    --tone-primary-bg-hue: 172;
    --tone-primary-bg-saturation: 28%;
    --tone-primary-bg-lightness: 22%;
    --tone-primary-color-hue: 175;
    --tone-primary-color-saturation: 84%;
    --tone-primary-color-lightness: 48%;
    --tone-primary-border-hue: 172;
    --tone-primary-border-saturation: 28%;
    --tone-primary-border-lightness: 47%;
    --tone-secondary-bg-hue: 172;
    --tone-secondary-bg-saturation: 13%;
    --tone-secondary-bg-lightness: 22%;
    --tone-secondary-color-hue: 172;
    --tone-secondary-color-saturation: 13%;
    --tone-secondary-color-lightness: 72%;
    --tone-secondary-border-hue: 172;
    --tone-secondary-border-saturation: 13%;
    --tone-secondary-border-lightness: 47%;
    --tone-success-color-hue: 76;
    --tone-success-color-saturation: 64%;
    --tone-success-color-lightness: 70%;
    --tone-success-bg-hue: 73;
    --tone-success-bg-saturation: 31%;
    --tone-success-bg-lightness: 25%;
    --tone-success-border-hue: 73;
    --tone-success-border-saturation: 31%;
    --tone-success-border-lightness: 60%;
    --tone-info-color-hue: 211;
    --tone-info-color-saturation: 72%;
    --tone-info-color-lightness: 90%;
    --tone-info-bg-hue: 210;
    --tone-info-bg-saturation: 15%;
    --tone-info-bg-lightness: 35%;
    --tone-info-border-hue: 210;
    --tone-info-border-saturation: 15%;
    --tone-info-border-lightness: 67%;
    --tone-warning-color-hue: 29;
    --tone-warning-color-saturation: 93%;
    --tone-warning-color-lightness: 75%;
    --tone-warning-bg-hue: 28;
    --tone-warning-bg-saturation: 40%;
    --tone-warning-bg-lightness: 25%;
    --tone-warning-border-hue: 28;
    --tone-warning-border-saturation: 40%;
    --tone-warning-border-lightness: 61%;
    --tone-danger-color-hue: 347;
    --tone-danger-color-saturation: 100%;
    --tone-danger-color-lightness: 90%;
    --tone-danger-bg-hue: 349;
    --tone-danger-bg-saturation: 20%;
    --tone-danger-bg-lightness: 35%;
    --tone-danger-border-hue: 349;
    --tone-danger-border-saturation: 20%;
    --tone-danger-border-lightness: 68%;
  }
}
:root, .theme-dark, .theme-light {
  --input-bg: hsl(var(--input-bg-hue), var(--input-bg-saturation), var(--input-bg-lightness));
  --placeholder: hsl(var(--placeholder-hue), var(--placeholder-saturation), var(--placeholder-lightness));
  --bg: hsl(var(--bg-hue), var(--bg-saturation), var(--bg-lightness));
  --color: hsl(var(--color-hue), var(--color-saturation), var(--color-lightness));
  --border: hsl(var(--border-hue), var(--border-saturation), var(--border-lightness));
  --tone-primary-bg: hsl(var(--tone-primary-bg-hue), var(--tone-primary-bg-saturation), var(--tone-primary-bg-lightness));
  --tone-primary-color: hsl(var(--tone-primary-color-hue), var(--tone-primary-color-saturation), var(--tone-primary-color-lightness));
  --tone-primary-border: hsl(var(--tone-primary-border-hue), var(--tone-primary-border-saturation), var(--tone-primary-border-lightness));
  --tone-secondary-bg: hsl(var(--tone-secondary-bg-hue), var(--tone-secondary-bg-saturation), var(--tone-secondary-bg-lightness));
  --tone-secondary-color: hsl(var(--tone-secondary-color-hue), var(--tone-secondary-color-saturation), var(--tone-secondary-color-lightness));
  --tone-secondary-border: hsl(var(--tone-secondary-border-hue), var(--tone-secondary-border-saturation), var(--tone-secondary-border-lightness));
  --tone-success-color: hsl(var(--tone-success-color-hue), var(--tone-success-color-saturation), var(--tone-success-color-lightness));
  --tone-success-bg: hsl(var(--tone-success-bg-hue), var(--tone-success-bg-saturation), var(--tone-success-bg-lightness));
  --tone-success-border: hsl(var(--tone-success-border-hue), var(--tone-success-border-saturation), var(--tone-success-border-lightness));
  --tone-info-color: hsl(var(--tone-info-color-hue), var(--tone-info-color-saturation), var(--tone-info-color-lightness));
  --tone-info-bg: hsl(var(--tone-info-bg-hue), var(--tone-info-bg-saturation), var(--tone-info-bg-lightness));
  --tone-info-border: hsl(var(--tone-info-border-hue), var(--tone-info-border-saturation), var(--tone-info-border-lightness));
  --tone-warning-color: hsl(var(--tone-warning-color-hue), var(--tone-warning-color-saturation), var(--tone-warning-color-lightness));
  --tone-warning-bg: hsl(var(--tone-warning-bg-hue), var(--tone-warning-bg-saturation), var(--tone-warning-bg-lightness));
  --tone-warning-border: hsl(var(--tone-warning-border-hue), var(--tone-warning-border-saturation), var(--tone-warning-border-lightness));
  --tone-danger-color: hsl(var(--tone-danger-color-hue), var(--tone-danger-color-saturation), var(--tone-danger-color-lightness));
  --tone-danger-bg: hsl(var(--tone-danger-bg-hue), var(--tone-danger-bg-saturation), var(--tone-danger-bg-lightness));
  --tone-danger-border: hsl(var(--tone-danger-border-hue), var(--tone-danger-border-saturation), var(--tone-danger-border-lightness));
}

@layer layout {
  :where(.tone-primary) {
    --bg-hue: var(--tone-primary-bg-hue);
    --bg-saturation: var(--tone-primary-bg-saturation);
    --bg-lightness: var(--tone-primary-bg-lightness);
    --bg: var(--tone-primary-bg);
    --border-hue: var(--tone-primary-border-hue);
    --border-saturation: var(--tone-primary-border-saturation);
    --border-lightness: var(--tone-primary-border-lightness);
    --border: var(--tone-primary-border);
    --color-hue: var(--tone-primary-color-hue);
    --color-saturation: var(--tone-primary-color-saturation);
    --color-lightness: var(--tone-primary-color-lightness);
    --color: var(--tone-primary-color);
    --tone-bg-hue: var(--tone-primary-bg-hue);
    --tone-bg-saturation: var(--tone-primary-bg-saturation);
    --tone-bg-lightness: var(--tone-primary-bg-lightness);
    --tone-bg: var(--tone-primary-bg);
    --tone-border-hue: var(--tone-primary-border-hue);
    --tone-border-saturation: var(--tone-primary-border-saturation);
    --tone-border-lightness: var(--tone-primary-border-lightness);
    --tone-border: var(--tone-primary-border);
    --tone-color-hue: var(--tone-primary-color-hue);
    --tone-color-saturation: var(--tone-primary-color-saturation);
    --tone-color-lightness: var(--tone-primary-color-lightness);
    --tone-color: var(--tone-primary-color);
    color: var(--color);
  }
  :where(.tone-secondary) {
    --bg-hue: var(--tone-secondary-bg-hue);
    --bg-saturation: var(--tone-secondary-bg-saturation);
    --bg-lightness: var(--tone-secondary-bg-lightness);
    --bg: var(--tone-secondary-bg);
    --border-hue: var(--tone-secondary-border-hue);
    --border-saturation: var(--tone-secondary-border-saturation);
    --border-lightness: var(--tone-secondary-border-lightness);
    --border: var(--tone-secondary-border);
    --color-hue: var(--tone-secondary-color-hue);
    --color-saturation: var(--tone-secondary-color-saturation);
    --color-lightness: var(--tone-secondary-color-lightness);
    --color: var(--tone-secondary-color);
    --tone-bg-hue: var(--tone-secondary-bg-hue);
    --tone-bg-saturation: var(--tone-secondary-bg-saturation);
    --tone-bg-lightness: var(--tone-secondary-bg-lightness);
    --tone-bg: var(--tone-secondary-bg);
    --tone-border-hue: var(--tone-secondary-border-hue);
    --tone-border-saturation: var(--tone-secondary-border-saturation);
    --tone-border-lightness: var(--tone-secondary-border-lightness);
    --tone-border: var(--tone-secondary-border);
    --tone-color-hue: var(--tone-secondary-color-hue);
    --tone-color-saturation: var(--tone-secondary-color-saturation);
    --tone-color-lightness: var(--tone-secondary-color-lightness);
    --tone-color: var(--tone-secondary-color);
    color: var(--color);
  }
  :where(.tone-success) {
    --bg-hue: var(--tone-success-bg-hue);
    --bg-saturation: var(--tone-success-bg-saturation);
    --bg-lightness: var(--tone-success-bg-lightness);
    --bg: var(--tone-success-bg);
    --border-hue: var(--tone-success-border-hue);
    --border-saturation: var(--tone-success-border-saturation);
    --border-lightness: var(--tone-success-border-lightness);
    --border: var(--tone-success-border);
    --color-hue: var(--tone-success-color-hue);
    --color-saturation: var(--tone-success-color-saturation);
    --color-lightness: var(--tone-success-color-lightness);
    --color: var(--tone-success-color);
    --tone-bg-hue: var(--tone-success-bg-hue);
    --tone-bg-saturation: var(--tone-success-bg-saturation);
    --tone-bg-lightness: var(--tone-success-bg-lightness);
    --tone-bg: var(--tone-success-bg);
    --tone-border-hue: var(--tone-success-border-hue);
    --tone-border-saturation: var(--tone-success-border-saturation);
    --tone-border-lightness: var(--tone-success-border-lightness);
    --tone-border: var(--tone-success-border);
    --tone-color-hue: var(--tone-success-color-hue);
    --tone-color-saturation: var(--tone-success-color-saturation);
    --tone-color-lightness: var(--tone-success-color-lightness);
    --tone-color: var(--tone-success-color);
    color: var(--color);
  }
  :where(.tone-info) {
    --bg-hue: var(--tone-info-bg-hue);
    --bg-saturation: var(--tone-info-bg-saturation);
    --bg-lightness: var(--tone-info-bg-lightness);
    --bg: var(--tone-info-bg);
    --border-hue: var(--tone-info-border-hue);
    --border-saturation: var(--tone-info-border-saturation);
    --border-lightness: var(--tone-info-border-lightness);
    --border: var(--tone-info-border);
    --color-hue: var(--tone-info-color-hue);
    --color-saturation: var(--tone-info-color-saturation);
    --color-lightness: var(--tone-info-color-lightness);
    --color: var(--tone-info-color);
    --tone-bg-hue: var(--tone-info-bg-hue);
    --tone-bg-saturation: var(--tone-info-bg-saturation);
    --tone-bg-lightness: var(--tone-info-bg-lightness);
    --tone-bg: var(--tone-info-bg);
    --tone-border-hue: var(--tone-info-border-hue);
    --tone-border-saturation: var(--tone-info-border-saturation);
    --tone-border-lightness: var(--tone-info-border-lightness);
    --tone-border: var(--tone-info-border);
    --tone-color-hue: var(--tone-info-color-hue);
    --tone-color-saturation: var(--tone-info-color-saturation);
    --tone-color-lightness: var(--tone-info-color-lightness);
    --tone-color: var(--tone-info-color);
    color: var(--color);
  }
  :where(.tone-warning) {
    --bg-hue: var(--tone-warning-bg-hue);
    --bg-saturation: var(--tone-warning-bg-saturation);
    --bg-lightness: var(--tone-warning-bg-lightness);
    --bg: var(--tone-warning-bg);
    --border-hue: var(--tone-warning-border-hue);
    --border-saturation: var(--tone-warning-border-saturation);
    --border-lightness: var(--tone-warning-border-lightness);
    --border: var(--tone-warning-border);
    --color-hue: var(--tone-warning-color-hue);
    --color-saturation: var(--tone-warning-color-saturation);
    --color-lightness: var(--tone-warning-color-lightness);
    --color: var(--tone-warning-color);
    --tone-bg-hue: var(--tone-warning-bg-hue);
    --tone-bg-saturation: var(--tone-warning-bg-saturation);
    --tone-bg-lightness: var(--tone-warning-bg-lightness);
    --tone-bg: var(--tone-warning-bg);
    --tone-border-hue: var(--tone-warning-border-hue);
    --tone-border-saturation: var(--tone-warning-border-saturation);
    --tone-border-lightness: var(--tone-warning-border-lightness);
    --tone-border: var(--tone-warning-border);
    --tone-color-hue: var(--tone-warning-color-hue);
    --tone-color-saturation: var(--tone-warning-color-saturation);
    --tone-color-lightness: var(--tone-warning-color-lightness);
    --tone-color: var(--tone-warning-color);
    color: var(--color);
  }
  :where(.tone-danger) {
    --bg-hue: var(--tone-danger-bg-hue);
    --bg-saturation: var(--tone-danger-bg-saturation);
    --bg-lightness: var(--tone-danger-bg-lightness);
    --bg: var(--tone-danger-bg);
    --border-hue: var(--tone-danger-border-hue);
    --border-saturation: var(--tone-danger-border-saturation);
    --border-lightness: var(--tone-danger-border-lightness);
    --border: var(--tone-danger-border);
    --color-hue: var(--tone-danger-color-hue);
    --color-saturation: var(--tone-danger-color-saturation);
    --color-lightness: var(--tone-danger-color-lightness);
    --color: var(--tone-danger-color);
    --tone-bg-hue: var(--tone-danger-bg-hue);
    --tone-bg-saturation: var(--tone-danger-bg-saturation);
    --tone-bg-lightness: var(--tone-danger-bg-lightness);
    --tone-bg: var(--tone-danger-bg);
    --tone-border-hue: var(--tone-danger-border-hue);
    --tone-border-saturation: var(--tone-danger-border-saturation);
    --tone-border-lightness: var(--tone-danger-border-lightness);
    --tone-border: var(--tone-danger-border);
    --tone-color-hue: var(--tone-danger-color-hue);
    --tone-color-saturation: var(--tone-danger-color-saturation);
    --tone-color-lightness: var(--tone-danger-color-lightness);
    --tone-color: var(--tone-danger-color);
    color: var(--color);
  }
}
@layer layout {
  .menu-tabbar {
    position: relative;
  }
  .menu-tabbar .toggle {
    padding: calc(var(--padding) / 2) var(--padding);
    background-color: hsl(var(--bg-hue), var(--bg-saturation), calc(var(--bg-lightness) + 5 * clamp(-1%, 50% - var(--bg-lightness), 1%)));
    border: solid 1px var(--border);
    border-radius: var(--radius);
    height: auto;
    display: block;
    width: 100%;
  }
  .menu-tabbar .toggle.toggled {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .menu-tabbar .options {
    background-color: hsl(var(--bg-hue), var(--bg-saturation), calc(var(--bg-lightness) + 7 * clamp(-1%, 50% - var(--bg-lightness), 1%)));
    border-bottom-right-radius: var(--radius);
    border-bottom-left-radius: var(--radius);
    border: solid 1px var(--border);
    position: absolute;
    border-top: none;
    width: 100%;
  }
  .menu-tabbar .options:not(.open) {
    display: none;
  }
  .menu-tabbar .options a {
    padding: var(--padding);
    display: block;
  }
  .menu-tabbar .options a.active {
    background-color: hsl(var(--bg-hue), var(--bg-saturation), calc(var(--bg-lightness) + 7 * clamp(-1%, 50% - var(--bg-lightness), 1%)));
  }
  .menu-tabbar .options a:hover {
    background-color: hsl(var(--bg-hue), var(--bg-saturation), calc(var(--bg-lightness) + 10 * clamp(-1%, 50% - var(--bg-lightness), 1%)));
  }
  .menu-tabbar .title {
    display: none;
  }
  @media screen and (min-width: 40rem) {
    .menu-tabbar {
      background: none;
    }
    .menu-tabbar a, .menu-tabbar .title {
      padding: calc(var(--padding) / 2) var(--padding);
      border-top-right-radius: var(--radius);
      border-top-left-radius: var(--radius);
      display: block;
      border: solid 1px var(--border);
      border-bottom-width: 0;
      margin-bottom: -1px;
      margin-right: -1px;
    }
    .menu-tabbar a {
      background: var(--bg);
    }
    .menu-tabbar .title {
      color: var(--tone-secondary-color);
      border-color: transparent;
      opacity: 0.5;
    }
    .menu-tabbar .options {
      justify-content: space-between;
      display: flex !important;
      position: relative;
      border-radius: 0;
      background: none;
      border: none;
      border-bottom: solid 1px var(--border);
    }
    .menu-tabbar .options .links {
      padding-left: var(--padding);
      display: flex;
    }
    .menu-tabbar .toggle {
      display: none;
    }
    .menu-tabbar .title {
      display: block;
    }
  }
}
.ql-toolbar {
  border-top-right-radius: 0.2rem !important;
  border-top-left-radius: 0.2rem !important;
  border-color: var(--border) !important;
  background-color: var(--bg);
  font-family: inherit;
}

.ql-snow .ql-stroke {
  stroke: currentColor;
}

quill-editor {
  display: block !important;
}

.ql-container {
  border-bottom-right-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
  border-color: var(--border) !important;
  background-color: var(--input-bg);
  font-size: inherit;
}
.ql-container .ql-editor {
  min-height: 10rem;
  cursor: text;
}

router-outlet {
  display: none;
}